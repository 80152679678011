import cookies from 'vue-cookies';
import config from '@/config';

export default {
    state: {
        selectedSeatMap: cookies.get(config.const.KEY_SELECTED_SEAT_MAP),
    },
    getters: {
        selectedSeatList: (state) => {
            let selectedSeatList = [];
            let selectedSeatMap = state.selectedSeatMap;
            if (selectedSeatMap) {
                for (let key in selectedSeatMap) {
                    selectedSeatList.push(selectedSeatMap[key]);
                }
            }
            return selectedSeatList;
        }
    },
    mutations: {
        setSelectedSeatMap(state, selectedSeatMap) {
            state.selectedSeatMap = Object.assign({}, selectedSeatMap);
            cookies.set(config.const.KEY_SELECTED_SEAT_MAP, selectedSeatMap, '0');
        },
    },
    actions: {
        initSelectedSeatMap({ commit }) {
            commit('setSelectedSeatMap', new Object());
        },
        changeSelectedSeat({ state, commit }, seat) {
            return new Promise((resolve, reject) => {
                if (!seat || seat.isSelected == undefined || seat.row == undefined || seat.column == undefined) {
                    return resolve();
                }
                if (!state.selectedSeatMap) {
                    commit('setSelectedSeatMap', new Object());
                }

                let seatKey = `${seat.row}:${seat.column}`;
                if (seat.isSelected) {
                    state.selectedSeatMap[seatKey] = seat;
                } else {
                    delete state.selectedSeatMap[seatKey];
                }
                commit('setSelectedSeatMap', state.selectedSeatMap);
            })
        }
    }
}