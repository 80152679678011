import cookies from 'vue-cookies';
import config from '@/config';

export default {
    state: {
        title: '',
        env_mode: cookies.get(config.const.KEY_ENV_MODE),
        appKey: cookies.get(config.const.KEY_APP_KEY),
        txId: cookies.get(config.const.KEY_TX_ID),
        platformType: {
            win: false,
            wx: false,
            Android: false,
            iOS: false,
        },
        positionFlag: false,
        position: cookies.get(config.const.KEY_MAP_POSITION),
        location: cookies.get(config.const.KEY_LOCATION), //第三方定位 { name: '上海', citycode: '310100' }
        selectedLocation: cookies.get(config.const.KEY_SELECTED_LOCATION), //手动选择定位
        selectedCounty: cookies.get(config.const.KEY_SELECTED_COUNTY), //手动选择的区县
        selectedHall: {},
    },
    getters: {
        env_mode(state) {
            return state.env_mode;
        },
        appKey(state) {
            return state.appKey ? state.appKey : config.defaultAppKey
        },
        txId(state) {
            return state.txId || void(0);
        },
        platform(state) {
            let platform = Object.keys(state.platformType).filter((key) => state.platformType[key] === true)
            return platform && platform.length > 0 ? platform[0] : void(0);
        },
        positionFlag(state) {
            return state.positionFlag;
        },
        position(state) {
            return state.position;
        },
        //用于综合展示的定位的code
        currentCityCode(state) {
            if (state.selectedLocation) {
                return state.selectedLocation.cityCode;
            }
            if (state.location) {
                return state.location.cityCode;
            }
            return config.defaultLocation.cityCode;
        },
        //用于综合展示的定位的name
        currentCityName(state) {
            if (state.selectedLocation) {
                return state.selectedLocation.name;
            }
            if (state.location) {
                return state.location.name;
            }
            return config.defaultLocation.name;
        },
        currentCountyCode: (state) => state.selectedCounty ? state.selectedCounty.id : '',
        currentCountyName: (state) => state.selectedCounty ? state.selectedCounty.name : config.defaultCounty.name,
        currentHallCode: (state) => state.selectedHall?.id
    },
    mutations: {
        setEnvMode(state, env_mode) {
            state.env_mode = env_mode;
            env_mode ? cookies.set(config.const.KEY_ENV_MODE, env_mode, '0') : cookies.remove(config.const.KEY_ENV_MODE);
        },
        setTitle(state, title) {
            state.title = title;
        },
        setAppKey(state, appKey) {
            state.appKey = appKey;
            appKey ? cookies.set(config.const.KEY_APP_KEY, appKey, '0') : cookies.remove(config.const.KEY_APP_KEY);
        },
        setTxId(state, txId) {
            state.txId = txId;
            cookies.set(config.const.KEY_TX_ID, txId, '0');
        },
        setPlatformType(state, platform) {
            state.platformType[platform] = true;
        },
        setPositionFlag(state, positionFlag) {
            state.positionFlag = positionFlag;
        },
        setPosition(state, position) {
            console.log('Store setPosition -> ,', JSON.stringify(position));
            if (position) {
                state.position = {
                    lat: position.lat,
                    lng: position.lng
                };
                cookies.set(config.const.KEY_MAP_POSITION, position, '1h');
                return;
            } else {
                console.log('Store Position -> position为空');
                state.position = null;
                cookies.remove(config.const.KEY_MAP_POSITION)
            }
        },
        setLocation(state, location) {
            if (location) {
                state.location = location;
                cookies.set(config.const.KEY_LOCATION, location, '0');
                return;
            }
            state.location = null;
            cookies.remove(config.const.KEY_LOCATION)
        },
        setSelectedLocation(state, selectedLocation) {
            state.selectedLocation = selectedLocation;
            cookies.set(config.const.KEY_SELECTED_LOCATION, selectedLocation, -1);
            this.commit('setSelectedCounty', null);
        },
        setSelectedCounty(state, selectedCounty) {
            state.selectedCounty = selectedCounty;
            if (selectedCounty) {
                cookies.set(config.const.KEY_SELECTED_COUNTY, selectedCounty, "0");
                return;
            }
            cookies.remove(config.const.KEY_SELECTED_COUNTY);
        },
        setSelectedHall(state, selectedHall) {
            
            state.selectedHall = selectedHall;
        }
    }
}
