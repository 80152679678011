const defaultCookieKey = {
    KEY_ENV_MODE: 'ENV_MODE',
    KEY_INVITER: 'INVITER',
    KEY_APP_KEY: 'APP_KEY',
    KEY_TX_ID: 'TX_ID',
    KEY_CITY_LIST: 'CITY_LIST',
    KEY_LOCATION: 'LOCATION',
    KEY_MAP_POSITION: 'MAP_POSITION',
    KEY_SELECTED_LOCATION: 'SELECTED_LOCATION',
    KEY_SELECTED_COUNTY: 'SELECTED_COUNTY',
    KEY_VERIFY_TIME: 'VERIFY_TIME',
    KEY_CURRENT_MOVIE: 'CURRENT_MOVIE',
    KEY_SELECTED_SEAT_MAP: 'SELECTED_SEAT_MAP',
    KEY_AUTH_CODE: 'AUTH_CODE',
    KEY_LOGIN_PHONE: 'LOGIN_PHONE',
    KEY_WX_OPEN_ID: 'WX_OPEN_ID',
    KEY_WX_OPEN_DATA: 'WX_OPEN_DATA',

    KEY_SEARCH_HISTORY_LIST: 'SEARCH_HISTORY_LIST',
}

export default (() => {
    let prefix = '_PXD_';
    let cookieKey = {};
    Object.keys(defaultCookieKey).forEach((key) => {
        cookieKey[key] = prefix + defaultCookieKey[key];
    });
    return cookieKey;
})();
