import config from '@/config';
import cookies from 'vue-cookies';
import router from '@/router';
import { getWxCode } from '@/libs/wx-utils';
import { getLoginVerifyCode, bindAndLogin, getMemberInfo, getBaseData } from '@api/permission-request'


export default {
    state: {
        memberInfo: {},
        subscribe: false,
        hasGetMemberInfo: false,
        inviter: cookies.get(config.const.KEY_INVITER),
        loginPhone: cookies.get(config.const.KEY_LOGIN_PHONE),
        verifyTime: cookies.get(config.const.KEY_VERIFY_TIME),
        authCode: cookies.get(config.const.KEY_AUTH_CODE),
        openId: cookies.get(config.const.KEY_WX_OPEN_ID),
        openData: cookies.get(config.const.KEY_WX_OPEN_DATA),
        baseData: {},
    },
    getters: {
        memberInfo(state) {
            return state.memberInfo || {};
        },
        subscribe(state) {
            return state.subscribe;
        },
        inviter(state) {
            return state.inviter || null;
        },
        authCode(state) {
            return state.authCode && state.authCode.length > 12 ? state.authCode : void(0);
        },
        loginPhone(state) {
            return state.loginPhone || void(0);
        },
        verifyTime(state) {
            return state.verifyTime || void(0);
        },
        openId(state) {
            return state.openId || void(0);
        },
        openData(state) {
            return state.openData || void(0);
        },
        hasGetMemberInfo(state) {
            return state.hasGetMemberInfo;
        },
        baseData(state) {
            return state.baseData;
        }
    },
    mutations: {
        setMemberInfo(state, memberInfo) {
            state.memberInfo = memberInfo;
        },
        setSubscribe(state, subscribe) {
            state.subscribe = subscribe;
        },
        setInviter(state, inviter) {
            state.inviter = inviter;
            inviter === false ? cookies.remove(config.const.KEY_INVITER) : cookies.set(config.const.KEY_INVITER, inviter, '7D');
        },
        setVerifyTime(state, dateTime) {
            state.verifyTime = dateTime;
            dateTime ? cookies.set(config.const.KEY_VERIFY_TIME, dateTime, new Date(dateTime)) : cookies.remove(config.const.KEY_VERIFY_TIME);
        },
        setLoginPhone(state, loginPhone) {
            state.loginPhone = loginPhone;
            loginPhone === false ? cookies.remove(config.const.KEY_LOGIN_PHONE) : cookies.set(config.const.KEY_LOGIN_PHONE, loginPhone, '7D');
        },
        setAuthCode(state, authCode) {
            state.authCode = authCode;
            authCode === false ? cookies.remove(config.const.KEY_AUTH_CODE) : cookies.set(config.const.KEY_AUTH_CODE, authCode, '7D');
        },
        setOpenId(state, openId) {
            state.openId = openId;
            openId === false ? cookies.remove(config.const.KEY_WX_OPEN_ID) : cookies.set(config.const.KEY_WX_OPEN_ID, openId, '7D');
        },
        setOpenData(state, openData) {
            state.openData = openData;
            openData === false ? cookies.remove(config.const.KEY_WX_OPEN_DATA) : cookies.set(config.const.KEY_WX_OPEN_DATA, openData, '7D');
        },
        setBaseData(state, baseData) {
            state.baseData = baseData;
        }
    },
    actions: {
        sendVerifyCode({ commit }, data) {
            return new Promise((resolve, reject) => {
                getLoginVerifyCode(data).then((res) => {
                    let currentDate = new Date();
                    commit('setVerifyTime', currentDate.getTime() + 1000 * 60 * 1);
                    resolve();
                }).catch((err) => {
                    reject(err);
                })
            })
        },
        loginByVerify({ rootGetters, commit }, { mobile, verifyCode, opendata }) {
            return new Promise((resolve, reject) => {
                let inviter = rootGetters.inviter;
                bindAndLogin({ mobile, verify: verifyCode, opendata, inviter}).then((res) => {
                    console.log('bindAndLogin rsp:' + res);
                    console.log('111:');
                    console.log(res);
                    let authCode = res.auth_code;
                    console.log('111:' + authCode);
                    let subscribe = res.subscribe; //是否关注了公众号 2023-3-6 19:27:51
                    console.log('222:' + subscribe);
                    console.log('333:');
                    if (rootGetters.platform == 'wx' && !rootGetters.openId) {
                        console.log('4444:');
                        let href = `${location.protocol}//${location.host}${router.options.base}${to.fullPath}`
                        console.log('href:' + href);
                        getWxCode(href).then(() => {
                            console.log('22');
                            commit('setAuthCode', authCode);
                            commit('setLoginPhone', mobile);
                            resolve();
                            let currentRoute = router.currentRoute;
                            let redirect = decodeURIComponent(currentRoute.query.redirect ? currentRoute.query.redirect : '/');
                            router.replace({
                                path: redirect
                            });
                        }).catch(() => {
                            reject({ errorMsg: '请先授权再登录' });
                            console.log('在微信环境中 拒绝授权了那就不给手机号登录');
                        });
                    } else {
                        console.log('333');
                        console.log(subscribe);
                        console.log(authCode);
                        console.log(mobile);
                        commit('setSubscribe', subscribe);
                        commit('setAuthCode', authCode);
                        commit('setLoginPhone', mobile);
                        resolve(subscribe);
                        // let currentRoute = router.currentRoute;
                        // let redirect = decodeURIComponent(currentRoute.query.redirect ? currentRoute.query.redirect : '/');
                        // router.replace({
                        //     path: redirect
                        // });
                    }

                }).catch((error) => {
                    console.log('bindAndLogin err:' + error);
                    // reject(error);
                })
            })
        },
        logout({ state, commit }) {
            return new Promise((resolve, reject) => {
                commit('setMemberInfo', {});
                commit('setAuthCode', false);
                commit('setLoginPhone', false);
                commit('setOpenId', false);
                state.hasGetMemberInfo = false;
                resolve();
            })
        },
        getMemberInfo({ state, commit }) {
            return new Promise((resolve, reject) => {
                getMemberInfo().then((res) => {
                    commit('setMemberInfo', {
                        id: res.id,
                        nickName: res.nickname,
                        avatar: res.headimgurl,
                        mobile: res.mobile,
                        memberAccountBalance: res.memberAccountBalance
                    });
                    state.hasGetMemberInfo = true;
                    resolve();
                }).catch(() => {
                    reject();
                })
            })
        },
        getBaseData({ state, commit}) {
            return new Promise((resolve, reject) => {
                getBaseData().then((res) => {
                    state.baseData = res;
                    resolve();
                })
            })
        }
    }
};
