import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./libs/number-utils";
import config from "./config";
import service from "./api-service";
import Vant from 'vant';
import { Toast } from 'vant';
import VConsole from 'vconsole';
import VueCookies from 'vue-cookies';
import VueInfiniteScroll from 'vue-infinite-scroll';
import VueMeta from 'vue-meta';
import VueUtils from '@/libs/vue-utils';
import filters from './filter';
import { Lazyload } from 'vant';
import 'vant/lib/index.less';
import "lib-flexible";

import {getCalendar} from '@/libs/date-utils'

import { getPlatform } from '@/libs/common-utils/common-utils';


Vue.config.productionTip = false;

Vue.use(Vant);
Vue.use(Lazyload);
Vue.use(VueCookies);
Vue.use(VueInfiniteScroll);
Vue.use(VueUtils);
Vue.use(filters);
Vue.use(VueMeta);


if (store.getters.env_mode && store.getters.env_mode == 'test') {
    Vue.use(new VConsole());
}

Vue.prototype.$config = config;
Vue.prototype.$service = service;

Toast.setDefaultOptions('loading', { duration: 0, forbidClick: true });

initializtionProgram();

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");

/**
 * 初始化工作 配置渠道入口
 */
function initializtionProgram() {

    let platform = getPlatform();
    store.commit('setPlatformType', platform);

    let env_mode = decodeURIComponent((new RegExp('[?|&]' + 'env_mode' + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
    let inviter = decodeURIComponent((new RegExp('[?|&]' + 'inviter' + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
    let appKey = decodeURIComponent((new RegExp('[?|&]' + 'appkey' + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
    let authCode = decodeURIComponent((new RegExp('[?|&]' + 'auth_code' + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
    let txId = decodeURIComponent((new RegExp('[?|&]' + 'txid' + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
    console.log(`main -> 初始化工作 appKey -> ${appKey}, authCode -> ${authCode}, txId -> ${txId}`);

    if (env_mode) store.commit('setEnvMode', env_mode);
    if (inviter) store.commit('setInviter', inviter);
    if (appKey) store.commit('setAppKey', appKey);
    if (authCode) store.commit('setAuthCode', authCode);
    if (txId) store.commit('setTxId', txId);


    //刷新Cookie
    store.getters.authCode ? store.commit('setAuthCode', store.getters.authCode) : void(0);
    store.getters.loginPhone ? store.commit('setLoginPhone', store.getters.loginPhone) : void(0);
    store.getters.openId ? store.commit('setOpenId', store.getters.openId) : void(0);

}
