import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store';
import { Toast, Dialog } from 'vant';

Vue.use(VueRouter);


export const routes = [
    //单独页面
    { path: '/test', name: 'test', component(resolve) { require(['@/views/Test'], resolve) }, meta: { keepAlive: true } },
    { path: "/", name: "/", redirect: '/index' },
    { path: '/index', name: 'index', component(resolve) { require(['@/views/home/index'], resolve) }, meta: { title: '', keepAlive: true } },
    { path: "/login", name: "login", component(resolve) { require(['@/views/login'], resolve) }, meta: { title: '绑定手机' } },
    { path: "/search", name: "search", component(resolve) { require(['@/views/search'], resolve) }, meta: { title: '搜索' } },
    { path: '/city', name: 'city', component(resolve) { require(['@/views/city'], resolve) }, meta: { title: '城市列表' } },
    { path: '/location-view', name: 'location-view', component(resolve) { require(['@/views/address/location-view'], resolve) }, meta: { title: '查看位置', requireAuth: true } },

    //电影
    { path: "/movie", name: "movie", component(resolve) { require(['@/views/movie'], resolve) }, meta: { title: '电影首页' } },
    { path: "/movie-detail", name: "movie-detail", component(resolve) { require(['@/views/movie/movie-detail'], resolve) }, meta: { title: '电影详情' } },

    //影院
    { path: "/cinema", name: "cinema", component(resolve) { require(['@/views/cinema'], resolve) }, meta: { title: '影院列表' } },
    { path: "/cinema-info", name: "cinema-info", component(resolve) { require(['@/views/cinema/cinema-info'], resolve) }, meta: { title: '影院信息' } },
    { path: '/movie-session', name: 'movie-session', component(resolve) { require(['@/views/cinema/movie-session'], resolve) }, meta: { title: '电影场次', requireAuth: true } },
    { path: '/cinema-seat', name: 'cinema-seat', component(resolve) { require(['@/views/cinema/cinema-seat'], resolve) }, meta: { title: '影院选座', requireAuth: true } },

    //订单
    { path: '/order-food', name: 'order-food', component(resolve) { require(['@/views/order/order-food'], resolve) }, meta: { title: '商品小食', requireAuth: false } },
    { path: '/order-pay', name: 'order-pay', component(resolve) { require(['@/views/order/order-pay'], resolve) }, meta: { title: '确认订单', requireAuth: true } },
    { path: '/order-pay-done', name: 'order-pay-done', component(resolve) { require(['@/views/order/order-pay-done'], resolve) }, meta: { title: '付款成功', requireAuth: true } },
    { path: '/order-coupon', name: 'order-coupon', component(resolve) { require(['@/views/order/order-coupon'], resolve) }, meta: { title: '选择优惠券', requireAuth: true } },


    //用户中心
    { path: "/user", name: "user", component(resolve) { require(['@/views/user'], resolve) }, meta: { title: '我的' } },
    { path: '/user-coupon', name: 'user-coupon', component(resolve) { require(['@/views/user/user-coupon'], resolve) }, meta: { title: '我的优惠券', requireAuth: true } },
    { path: '/user-coupon-bind', name: 'user-coupon-bind', component(resolve) { require(['@/views/user/user-coupon-bind'], resolve) }, meta: { title: '绑定优惠券', requireAuth: true } },
    // { path: '/user-vip', name: 'user-vip', component(resolve) { require(['@/views/user/user-vip'], resolve) }, meta: { title: '贵宾卡', requireAuth: true } },
    { path: "/feedback", name: "feedback", component(resolve) { require(['@/views/user/other/feedback'], resolve) }, meta: { title: '意见反馈' } },
    { path: '/user-share', name: 'user-share', component(resolve) { require(['@/views/user/other/user-share'], resolve) }, meta: { title: '我要分享', requireAuth: true } },
    { path: "/gzh", name: "gzh", component(resolve) { require(['@/views/user/other/gzh'], resolve) }, meta: { title: '关注公众号' } },
    //deprecated
    // { path: "/recharge", name: "recharge", component(resolve) { require(['@/views/user/recharge'], resolve) }, meta: { title: '充值' } },
    //余额相关
    { path: "/balance", name: "user-balance", component(resolve) { require(['@/views/user/user-balance/user-balance'], resolve) }, meta: { title: '我的余额' } },
    { path: "/transaction", name: "user-transaction", component(resolve) { require(['@/views/user/user-balance/user-transaction'], resolve) }, meta: { title: '交易明细' } },

    
    //订单-电影订单
    { path: '/movie-order', name: 'movie-order', component(resolve) { require(['@/views/user/user-order/movie-order'], resolve) }, meta: { title: '我的订单', requireAuth: true } },
    { path: '/movie-order-detail', name: 'movie-order-detail', component(resolve) { require(['@/views/user/user-order/movie-order-detail'], resolve) }, meta: { title: '电影', requireAuth: true } },
    { path: "/reqeust-refund", name: "request-refund", component(resolve) { require(['@/views/user/user-order/request-refund'], resolve) }, meta: { title: '申请退款' } },
    { path: '/food-order', name: 'food-order', component(resolve) { require(['@/views/user/user-order/food-order'], resolve) }, meta: { title: '小食订单', requireAuth: true } },
    { path: '/food-order-detail', name: 'food-order-detail', component(resolve) { require(['@/views/user/user-order/food-order-detail'], resolve) }, meta: { title: '小食订单详情', requireAuth: true } },

    //订单-充值订单
    { path: '/store-order', name: 'store-order', component(resolve) { require(['@/views/user/user-order/store-order'], resolve) }, meta: { title: '充值订单', requireAuth: false } },
    { path: "/store-order-detail", name: "store-order-detail", component(resolve) { require(['@/views/user/user-order/store-order-detail'], resolve) }, meta: { title: '订单详情',  requireAuth: true } },


    //商城充值
    { path: "/store", name: "store", component(resolve) { require(['@/views/store/store'], resolve) }, meta: { title: '音乐视频' } },
    { path: "/store-sku", name: "store-sku", component(resolve) { require(['@/views/store/store-sku'], resolve) }, meta: { title: '充值下单',  requireAuth: true } },
    { path: "/store-order-pay", name: "store-order-pay", component(resolve) { require(['@/views/store/store-order-pay'], resolve) }, meta: { title: '确认支付',  requireAuth: true } },


    { path: "/agree", name: "agree", component(resolve) { require(['@/views/user/other/agree'], resolve) }, meta: { title: '服务协议' } },
    //错误页
    { path: '/error/:errorCode', name: 'error', component(resolve) { require(['@/views/error-page'], resolve) } },
    { path: '*', name: 'error404', component(resolve) { require(['@/views/error-page/404'], resolve) }, meta: { title: '错误404' } },
    
];

const router = new VueRouter({
    mode: "history",
    // base: process.env.BASE_URL,
    routes,
    // scrollBehavior(to, from, savedPosition) {
    //     console.log(savedPosition)
    //     if (savedPosition) {
    //         return savedPosition
    //     } else {
    //         return { x: 0, y: 0 }
    //     }
    // }
});

router.beforeEach((to, from, next) => {
    console.log('beforeEach')
    console.log(to)
    console.log(from)
    // next();return;
    if (to.meta.requireAuth && !store.getters.authCode) {
        Toast('请先登录');
        next({
            path: '/login',
            query: {
                redirect: to.fullPath
            }
        });
    } else if (to.name != 'login' && to.name.indexOf('error') == -1 && store.getters.authCode) {
        if (store.getters.hasGetMemberInfo) {
            next();
        } else {
            store.dispatch('getBaseData');
            store.dispatch('getMemberInfo').then(() => {
                next();
            }).catch(() => {
                next();
            })
        }
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {

    console.log('beforeEach2')
    console.log(to)
    console.log(from)
    let title = to.meta ? to.meta.title : '';
    document.title = title;
    store.commit('setTitle', title);
    next();
})


export default router;
