//项目主题样式
import style from '@/styles/project.scss';
//Key常量集合
import storageKey from './storage-key';

import store from '@/store';

const config = {
    const: storageKey,
    style, //全局样式
    remUnit: 37.5,
    requestTimeout: 1000 * 10, //默认请求超时时间
    defaultAppKey: "defaultAppKey", //默认渠道appKey
    wxAppId: 'wxdf5bfcc00ef6e20b',
    // wxAppId: 'wx6304a1a8b96a34d1',
    signKey: "D8972106E4349D541C90B0C28C7CD4E7",
    defaultLocation: {
        cityCode: '310100',
        name: '上海',
    },
    defaultCounty: {
        name: '全城'
    },
    baseUrl: {
        dev: "",
        prod: "",
    },
    loadingMsg: '加载中',
    emptyPlaceholder: '　',
    getDefaultPagination: () => {
        return {
            pageNo: 1,
            pages: 0,
            total: 0,
            pageSize: 20,
            next: false,
        }
    },
};

export default Object.assign(config);
