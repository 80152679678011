import _config from '@/config';
import store from '@/store';
import { Toast } from 'vant';
import wx from 'weixin-js-sdk';
import { getOpenIdAndLoginByWxCode} from '@api/permission-request'

export function wxLogin(redirectUrl) {
    console.log('wxLogin')
    console.log(redirectUrl)
    return new Promise((resolve, reject) => {
        let code = decodeURIComponent((new RegExp('[?|&]' + 'code' + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
        if (code) {
            console.log('auth ok');
            getOpenIdAndLoginByWxCode(code).then((res) => {
                console.log(res);
                if (res.openid) {
                    store.commit('setOpenId', res.openid);
                }
                if (res.opendata) {
                    store.commit('setOpenData', res.opendata);
                    resolve();
                } else if (res.auth_code) {
                    store.commit('setAuthCode', res.auth_code);
                    resolve(true);
                }
                resolve();
            }).catch((err) => {
                console.log(err)
                Toast.fail('微信授权失败');
                reject(false);
            });
        } else {
            console.log("redirectUrl")
            let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${_config.wxAppId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect`;
            // let url = redirectUrl + '&code=abcd123456'
            window.location.replace(url);
            reject(false);
        }

    })

}

export function wxPaymentV2(config, callback) {
    if (typeof WeixinJSBridge === 'undefined') { // 微信浏览器内置对象。参考微信官方文档
        if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady(config), false)
        } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady(config))
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady(config))
        }
    } else {
        console.log('准备调用微信支付')
        onBridgeReady(config, callback);
    }
    
}

function onBridgeReady(config, callback) {
    WeixinJSBridge.invoke('getBrandWCPayRequest', {
        appId: config.appId,
        timeStamp: config.timeStamp,
        nonceStr: config.nonceStr,
        package: config.package,
        signType: config.signType,
        paySign: config.paySign,
    }, (res) => {
        console.log('调用成功');
        console.log(res);
        if (res.err_msg === 'get_brand_wcpay_request:ok') {
            if (callback) {
                callback();
            }
        }
    })
}


export function wxPaymentV3(wxConfig) {
    return new Promise((resolve, reject) => {
        readyConfig(wxConfig).then((res) => {
            wx.chooseWXPay({
                appId: wxConfig.appId,
                timestamp: wxConfig.timeStamp,
                nonceStr: wxConfig.nonceStr,
                package: wxConfig.package,
                paySign: wxConfig.paySign,
                success: function(res) {
                    console.log('支付好了');
                    resolve();
                },
                cancel: function(res) {
                    console.log('取消支付');
                },
                fail: function(res) {
                    console.log('支付失败');
                }
            })
        });
    })
}

function readyConfig({ appId, timeStamp, nonceStr, signType }) {
    return new Promise((resolve, reject) => {
        wx.config({
            debug: true,
            appId: appId,
            timestamp: timeStamp,
            nonceStr: nonceStr,
            signType: signType,
            jsApiList: ['chooseWXPay'],
        });
        wx.ready(() => {
            resolve();
        });

        wx.error(() => {
            console.log('微信配置错误');
            reject();
        })
    })
}
