import moment from 'moment';
import { calendarFormatMapper, getCalendarFormatterByDays } from './moment-config';

moment.locale('zh-cn'); //设置中文
moment.suppressDeprecationWarnings = true; //关闭警告

//默认更改一个日历格式化
changeCalendarFormat();

export function test(date = new Date()) {
    let result;
    result = moment(date).format('YYYYMMDDHHmmss');
    console.log(result)
    return result;
}

export function momentFormatter(date = new Date(), format = 'YYYY-MM-DD HH:mm:ss') {
    return moment(date).format(format);
}

/**
 * 获取日期的timestamp
 * @param {默认当前日期}} date
 * @returns
 */
export function getTimestamp(date = new Date()) {
    return moment(date).format('YYYYMMDDHHmmss');
}


/**
 * 更改格式化日期表达式
 * @param {格式化} formatIndex 
 */
function changeCalendarFormat(formatIndex = 0) {
    moment.calendarFormat = getCalendarFormatterByDays(formatIndex);
}

/**
 * 获得日期的日历形式,如:昨天,今天,明天,后天,本周,下周
 * @param {目标日期} date 
 * @param {格式化映射结果的下标}} mapperIndex 
 * @param {格式化表达式的下标} formatIndex 
 * @returns 
 */
export function getCalendar(date = new Date(), mapperIndex = 0, formatIndex = 0) {
    changeCalendarFormat(formatIndex);
    let referenceDay = null; //参考日 为空默认当前日期
    return moment(date).calendar(referenceDay, calendarFormatMapper[mapperIndex]);
}

export function getDateDiff(date = new Date(), unit = 'days') {
    let diff = Math.abs(moment(new Date()).diff(date, unit));
    return diff;
}


/**
 * 
 * 日期转换成"月 - 日 星期"
 * @param {默认当前日期} date 
 */
export function formatDateToMonthDayWeek(date = new Date(), week = false) {
    date = new Date(date);
    let weekDayArr = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"]
    let weekDay = date.getDay() //获取当前星期X(0-6,0代表星期天)
    let month = date.getMonth() + 1 //获取当前星期X(0-6,0代表星期天)
    let day = date.getDate() //获取当前星期X(0-6,0代表星期天)
    if (week) {
        return weekDayArr[weekDay] + month + '月' + day + '日';
    } else {
        return month + '月' + day + '日';
    }
}

let weekArray = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"]
/**
 * @deprecated 原通过字符串替换格式化日期,现使用moment类格式化 2021-9-4
 * @param {目标日期} date 
 * @param {格式化表达式} format 
 * @returns 
 */
export function dateFormatter(date = new Date(), format = 'yyyy-MM-dd') {
    date = date.replace(/-/g,'/');
    date = new Date(date);
    let o = {
        "M+": date.getMonth() + 1, //month
        "d+": date.getDate(), //day
        "h+": date.getHours(), //hour
        "m+": date.getMinutes(), //minute
        "s+": date.getSeconds(), //second
        "q+": Math.floor((date.getMonth() + 3) / 3), //quarter
        "S": date.getMilliseconds(), //millisecond
        "W+": weekArray[date.getDay()],
    }
    if (/(y+)/.test(format)) format = format.replace(RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
        }

    return format;
}

/**
 * 秒数转换成分钟数 如360秒 = 10:00;
 * @param {秒数} second 
 * @returns 
 */
export function formatSecondToMin(second) {
    let result = parseInt(second)
    let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
    let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
    let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

    let res = '';
    if (h !== '00') res += `${h}:`;
    res += `${m}:`;
    res += `${s}`;
    return res;
}

/**
 * 日期分割为 时间
 * @param {默认当前日期}} date 
 * @returns 
 */
export function formatDateToTime(date = new Date()) {
    return date.slice(11, 16)
}
