import axios from "../axios";


export function getOpenIdAndLoginByWxCode(code) {
    console.log('getOpenIdAndLoginByWxCode')
    const url = `/wx/open/get`;
    return axios.request({
        url,
        method: 'get',
        data: {
            code
        }
    })
}


export function getLoginVerifyCode(data) {
    const url = `/mobile/code/send`;
    return axios.request({
        url,
        method: 'get',
        data,
    })
}

export function bindAndLogin(data) {
    const url = `/mobile/reg`;
    return axios.request({
        url,
        method: 'get',
        data
    })
}

export function getMemberInfo() {
    const url = `/member/get`;
    return axios.request({
        url,
        method: 'get',
    })
}


export function getCityList() {
    const url = '/city/list';
    return axios.request({
        url,
        method: 'get'
    })
}

export function getSpecialHallList() {
    const url = '/cinema/special/hall/list';
    return axios.request({
        url,
        method: 'get'
    })
}

export function getCountyList(data) {
    const url = '/county/list';
    return axios.request({
        url,
        method: 'get',
        data,
    })
}


export function getBaseData() {
    const url = '/baseData';
    return axios.request({
        url,
        method: 'get'
    })
}

export function submitFeedback(data) {
    const url = '/feedback';
    return axios.request({
        url,
        method: 'post',
        data
    })
}


export function getShareImage() {
    const url = '/member/share/data';
    return axios.request({
        url,
    })
}

export function createShareImage() {
    const url = '/member/share/createPic';
    return axios.request({
        url
    })
}


