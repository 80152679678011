import Vue from "vue";
import Vuex from "vuex";
import app from './module/app.js';
import user from './module/user.js';
import movie from './module/movie.js';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        app,
        user,
        movie
    },
});