<template>
    <div id="app">
        <!-- <van-nav-bar v-if="showNavBar" :title="title" left-arrow ref="navBar" fixed placeholder @click-left="goBack" :border="true"></van-nav-bar> -->
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive" class="keep"/>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
    </div>
</template>

<script>
export default {
    computed: {
        title() {
            return this.$store.state.app.title;
        },
        platformType() {
            return this.$store.state.app.platformType;
        },
        showNavBar() {
            if (this.platformType.win || this.platformType.iOS) {
                return true;
            }
            return false;
        }
    },
    methods: {
        goBack() {
            this.$goPage(-1);
        }
    },
    mounted() {
        Object.keys(this.platformType).forEach((key) => {
            if (this.platformType[key]) {
                console.log('当前运行环境 ->', key);
            }
        })
    }
}
</script>
<style>
/* @font-face {
	font-family: "pf regular";
	src: url("./assets/font/苹方黑体-准-简.ttf");
}

@font-face {
	font-family: "pf medium";
	src: url("./assets/font/苹方黑体-中黑-简.ttf");
} */
</style>
<style lang="scss">
@import "./styles/index.scss";
html,
body,
#app {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	// font-family: "pf regular";
    font: Microsoft YaHei,sans-serif;
    color: #333;
	-webkit-font-smoothing: antialiased;
}

#app {
	display: flex;
	flex-direction: column;
}

body {
    max-width: 414px;
    // margin-left: calc((100% - 414px) / 2) !important;
    position: relative;
}

.van-nav-bar {
	.van-icon-arrow-left {
		color: $second-color !important;
	}
}

#__vconsole {
    position: fixed;
    z-index: 999999;
}
</style>
