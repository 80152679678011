import debounce from './debounce';
import throttle from './throttle';


export function getCssRule(name) {
    var animation={};
	var rule,cssRule;
	var ss = document.styleSheets;
	for (var i = 0; i < ss.length; ++i) {
		for (var x = 0; x < ss[i].rules.length; ++x) {
			rule = ss[i].rules[x];
			if (rule.name == name && rule.type == 7) {
				animation.cssRule = rule;
				animation.styleSheet = ss[i];
				animation.index = x;
			}
		}
	}
	return animation;
}

const $common = {
    debounce,
    throttle,
}

export default $common;
