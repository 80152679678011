import { formatDateToMonthDayWeek, formatDateToTime, dateFormatter, formatSecondToMin } from "../libs/date-utils";
import { formatIntToMoney, formatDistance, hideMobile } from '@/libs/common-utils/common-utils';

const filters = {
    formatDateToMonthDayWeek,
    formatDateToTime,
    formatIntToMoney,
    formatDistance,
    formatSecondToMin,
    dateFormatter,
    hideMobile
}

export default {
    install(Vue) {
        Object.keys(filters).forEach(key => {
            Vue.filter(key, filters[key])
        })
    }
}